.wrapper {
	border: 10px solid red;

	.App {
		border: 10px solid green;
	}

	.h1 {
		font-size: 180px;
	}

	h1 {
		font-size: 80px;
	}
}
